import { createQuery } from '../helpers'
import type {
  UserDTO,
  GetUsersParams,
  GetUserParams,
  UpdateUserFlagsParams,
  UpdateUserOrganisationParams,
  UpdateUserAttendantParams,
  UpdateUserLastLoginParams
} from '../../../types/user'
import type { PaginatedResponse, Query } from '../../../types/api'

const returnProps = `
  id
  givenName
  email
  familyName
  phoneNumber
  groups
  flags
`

const returnPropsWithoutFlags = `
  id
  givenName
  email
  familyName
  phoneNumber
  groups
`
const getUsers: Query<PaginatedResponse<UserDTO>, GetUsersParams> = createQuery(`
  query getUsersNew(
  $group: String, $givenName: String, $familyName: String, $flags: [Flag], $next: String, $limit: Int) {
    getUsersNew(
    group: $group, 
    givenName: $givenName, 
    familyName: $familyName, flags: $flags, next: $next, limit: $limit) {
      items {
        ${returnProps}
      }
      next
      limit
    }
  }
`)

const getUsersWithoutFlags: Query<PaginatedResponse<UserDTO>, GetUsersParams> = createQuery(`
  query getUsers($group: String, $query: String, $next: String, $limit: Int) {
    getUsers(group: $group, query: $query, next: $next, limit: $limit) {
      items {
        ${returnPropsWithoutFlags}
      }
      next
      limit
    }
  }
`)

const getUser: Query<UserDTO, GetUserParams> = createQuery(`
  query getUser($id: ID!) {
    getUser(id: $id) {
      ${returnProps}
    }
  }
`)

const getOrganisationAdminId: Query<string | null, {id: string}> = createQuery(`
  query getOrganisationAdminId($id: ID!) {
    getOrganisationAdminId(id: $id)
  }
`)

const getAttendantLocationId: Query<string | null, {id: string}> = createQuery(`
  query getAttendantLocationId($id: ID!) {
    getAttendantLocationId(id: $id)
  }
`)

const addUserFlags: Query<boolean, UpdateUserFlagsParams> = createQuery(`
  mutation addUserFlags(
    $id: ID!,
    $flags: [Flag!]
  ) {
    addUserFlags(id: $id, flags: $flags)
  }
`)

const removeUserFlags: Query<boolean, UpdateUserFlagsParams> = createQuery(`
  mutation removeUserFlags(
    $id: ID!,
    $flags: [Flag!]
  ) {
    removeUserFlags(id: $id, flags: $flags)
  }
`)

const disableUser: Query<boolean, GetUserParams> = createQuery(`
  mutation disableUser($id: ID!) {
    disableUser(id: $id)
  }
`)

const addUserOrganisationAdmin: Query<boolean, UpdateUserOrganisationParams> = createQuery(`
  mutation addUserOrganisationAdmin(
    $id: ID!,
    $adminOrganisationId: String
  ) {
    addUserOrganisationAdmin(id: $id, adminOrganisationId: $adminOrganisationId)
  }
`)

const removeUserOrganisationAdmin: Query<boolean, { id: string }> = createQuery(`
  mutation removeUserOrganisationAdmin(
    $id: ID! 
  ) {
    removeUserOrganisationAdmin(id: $id)
  }
`)

const addAttendantLocationId: Query<boolean, UpdateUserAttendantParams> = createQuery(`
  mutation addAttendantLocationId(
    $id: ID!,
    $attendantLocationId: String
  ) {
    addAttendantLocationId(id: $id, attendantLocationId: $attendantLocationId)
  }
`)

const updateUserLastLogin: Query<boolean, UpdateUserLastLoginParams> = createQuery(`
  mutation updateUserLastLogin(
    $id: ID!
  ) {
    updateUserLastLogin(id: $id)
  }
`)

const addOperatorAdmin: Query<boolean, { id: string }> = createQuery(`
  mutation addOperatorAdmin(
    $id: ID!
  ) {
    addOperatorAdmin(id: $id)
  }
`)

const addSystemAdmin: Query<boolean, { id: string }> = createQuery(`
  mutation addSystemAdmin(
    $id: ID!
  ) {
    addSystemAdmin(id: $id)
  }
`)

const removeAttendant: Query<boolean, { id: string }> = createQuery(`
  mutation removeAttendant(
    $id: ID!
  ) {
    removeAttendant(id: $id)
  }
`)

const removeOperatorAdmin: Query<boolean, { id: string }> = createQuery(`
  mutation removeOperatorAdmin(
    $id: ID!
  ) {
    removeOperatorAdmin(id: $id)
  }
`)

const removeSystemAdmin: Query<boolean, { id: string }> = createQuery(`
  mutation removeSystemAdmin(
    $id: ID!
  ) {
    removeSystemAdmin(id: $id)
  }
`)

export {
  disableUser,
  getUsers,
  getUsersWithoutFlags,
  getUser,
  getOrganisationAdminId,
  addUserFlags,
  removeUserFlags,
  addUserOrganisationAdmin,
  removeUserOrganisationAdmin,
  addAttendantLocationId,
  getAttendantLocationId,
  addOperatorAdmin,
  removeAttendant,
  removeOperatorAdmin,
  updateUserLastLogin,
  addSystemAdmin,
  removeSystemAdmin
}
