import React, {
  ReactElement, useEffect, useState
} from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Input,
  Panel, Table, Tag, TagGroup,
  SelectPicker
} from 'rsuite'

import {
  useApi, useModal, usePageTitle, useUser
} from '../../app/hooks'
import {
  getUsers,
  addUserFlags,
  removeUserFlags,
  // removeUserOrganisationAdmin,
  addOperatorAdmin,
  removeAttendant,
  removeOperatorAdmin,
  removeSystemAdmin,
  addSystemAdmin
} from '../../services/graphql/queries'
import {
  PaginationControls, TableData, ActionMenu, Modal
} from '../../components'
import type { RowData } from '../../types/table'
import { usePaginatedApi } from '../../app/hooks/paginatedApi'
import './UserList.css'
import { formatUserDisplayName, formatUserRoles } from './userHelpers'
import { Flag, UserRole } from '../../types/enums'
import { OrganisationAssignModal } from '../../components/OrganisationAssignModal'
import { AttendantAssignModal } from '../../components/AttendantAssignModal'
import { DeleteUserModal } from '../../components/DeleteUserModal'
import { useDebounce } from '../../app/hooks/debounce'

/**
 * User list component
 * @return {ReactElement}
 */
export function UserList (): ReactElement {
  usePageTitle('Users')

  const authUser = useUser()
  const addUserFlagQuery = useApi(addUserFlags)
  const addOperatorQuery = useApi(addOperatorAdmin)
  const removeUserFlagQuery = useApi(removeUserFlags)
  const removeAttendantQuery = useApi(removeAttendant)
  const removeOperatorQuery = useApi(removeOperatorAdmin)
  const removeAdminQuery = useApi(removeSystemAdmin)
  const addAdminQuery = useApi(addSystemAdmin)
  // const removeOrganisationQuery = useApi(removeUserOrganisationAdmin)
  const usersQuery = usePaginatedApi({
    query: getUsers,
    itemsPerPage: 20,
    fetchParams: {},
  })

  const modal = useModal()
  const attendantModal = useModal()
  const deleteUserModal = useModal()

  /**
   * Render a name cell for a single user
   * @param {User} user
   * @return {ReactElement}
   */
  const renderName = (user: RowData): ReactElement => (
    <Link to={user.id}>{formatUserDisplayName(user as any)}</Link>
  )

  /**
   * Render a email cell for a single user
   * @param {User} user
   * @return {ReactElement}
   */
  const renderEmail = (user: RowData): ReactElement => (
    <div>{user.email}</div>
  )

  /**
   * Render a roles cell for a single user
   * @param {User} user
   * @return {ReactElement}
   */
  const renderRoles = (user: RowData): ReactElement => <div>{formatUserRoles(user as any)}</div>
  const renderFlags = (user: RowData): ReactElement => (
    <div>{user.flags?.map((f: string) => (<TagGroup><Tag>{f}</Tag></TagGroup>))}</div>
  )

  const users = usersQuery.currentPage || []

  const [familyNameSearch, setFamilyNameSearch] = useState('')
  const dFamilyNameSearch = useDebounce(familyNameSearch, 500)

  const [givenNameSearch, setGivenNameSearch] = useState('')
  const dGivenNameSearch = useDebounce(givenNameSearch, 500)

  const [flag, setFlag] = useState<Flag[]>([])

  const formatFlagData = (res: any) => res?.map(
    (d) => ({ label: d, value: d })
  ) ?? []

  /**
   *  Resets the query data and makes a new request with
   *  the search string in the search bar
   *  @param {String?} query          - The search string
   *  @param {String[]?} filterFlags  - The flags to filter users by, leave null if all flags are wanted
   */
  const performSearch = (
    familyName: string | null,
    givenName: string | null,
    filterFlags: string[] | null
  ) => {
    usersQuery.reset()
    const hook = usersQuery.apiHook
    hook.sendRequest({
      familyName: familyName ?? '',
      givenName: givenName ?? '',
      flags: filterFlags as any ?? undefined,
      next: null,
      limit: 20,
    })
  }

  /**
   *  Cb when the search button is clicked
   */
  const searchUsers = () => {
    performSearch(dFamilyNameSearch, dGivenNameSearch, flag)
  }

  useEffect(() => {
    searchUsers()
  }, [dFamilyNameSearch, dGivenNameSearch, flag])

  /**
   *  Cb when the tag picker value changes
   *  @param {String[]} value - The values chosen in the TagPicker
   */
  const onTagPickerChange = (value:string[]) => {
    setFlag(value as any)
    performSearch(familyNameSearch, givenNameSearch, value)
  }

  const blockUser = async (user: RowData) => {
    await addUserFlagQuery.sendRequest({
      id: user.id,
      flags: [Flag.ADMIN_BLOCKED],
    })
    setTimeout(() => searchUsers(), 500)
  }
  const unblockUser = async (user: RowData) => {
    await removeUserFlagQuery.sendRequest({
      id: user.id,
      flags: [Flag.ADMIN_BLOCKED],
    })
    setTimeout(() => searchUsers(), 500)
  }
  const addOperator = async (user: RowData) => {
    await addOperatorQuery.sendRequest({
      id: user.id,
    })
    setTimeout(() => searchUsers(), 500)
  }

  const addAdmin = async (user: RowData) => {
    await addAdminQuery.sendRequest({
      id: user.id,
    })
    setTimeout(() => searchUsers(), 500)
  }

  const removeAdmin = async (user: RowData) => {
    await removeAdminQuery.sendRequest({
      id: user.id,
    })
    setTimeout(() => searchUsers(), 500)
  }

  const removeOperatorPermissions = async (user: RowData) => {
    await removeOperatorQuery.sendRequest({
      id: user.id,
    })
    setTimeout(() => searchUsers(), 500)
  }

  // const removeOrganisationPermissions = async (user: RowData) => {
  //  await removeOrganisationQuery.sendRequest({
  //    id: user.id,
  //  })
  //  setTimeout(() => searchUsers(), 500)
  // }

  const removeAttendantPermissions = async (user: RowData) => {
    await removeAttendantQuery.sendRequest({
      id: user.id,
    })
    setTimeout(() => searchUsers(), 500)
  }

  /**
   * Dropdown menu for a row in the users list table
   * @param {RowData} user - The user object of the row
   * @return {ReactElement}
   */
  const renderActions = (user: RowData): ReactElement => {
    const actions = [
      { label: 'Assign as organisation admin', action: () => modal.show(user.id) },
    ]
    if (authUser.hasRole(UserRole.ADMIN)) {
      if (user.groups?.includes(UserRole.OPERATOR)) {
        actions.push({ label: 'Remove as carpark operator', action: () => removeOperatorPermissions(user) })
      } else {
        actions.push({ label: 'Assign as carpark operator', action: () => addOperator(user) })
      }
      if (user.groups?.includes(UserRole.ADMIN)) {
        actions.push({ label: 'Remove as system admin', action: () => removeAdmin(user) })
      } else {
        actions.push({ label: 'Assign as system admin', action: () => addAdmin(user) })
      }
    }

    if (user.groups?.includes(UserRole.ATTENDANT)) {
      actions.push({ label: 'Remove as carpark attendant', action: () => removeAttendantPermissions(user) })
    } else {
      actions.push({ label: 'Assign as carpark attendant', action: () => attendantModal.show(user.id) })
    }
    actions.push({ label: '-', action: () => null })
    if (user.flags?.includes(Flag.ADMIN_BLOCKED)) {
      actions.push({ label: 'Unblock', action: () => unblockUser(user) })
    } else {
      actions.push({ label: 'Block', action: () => blockUser(user) })
    }
    actions.push({ label: 'Delete', action: () => deleteUserModal.show(user) })

    return (
      <ActionMenu actions={actions} />
    )
  }

  return (
    <>
      <Panel
        header={(
          <h2>Users</h2>
        )}
        className="user-list"
      >
        <div className="search">
          <div className="flag-filter">
            {/* eslint-disable-next-line  jsx-a11y/label-has-associated-control */}
            <label htmlFor="flags">Flag filter: </label>
            <SelectPicker
              id="flag"
              name="flag"
              preventOverflow
              value={flag}
              data={formatFlagData(Object.values(Flag))}
              onChange={onTagPickerChange}
            />
          </div>
          <div className="search-bar">
            <div className="input">
              {/* eslint-disable-next-line  jsx-a11y/label-has-associated-control */}
              <label htmlFor="search">Last name:</label>
              <Input
                onKeyDown={(e) => {
                  if (e.code === 'Enter') searchUsers()
                }}
                value={familyNameSearch}
                onChange={(s) => setFamilyNameSearch(s)}
                name="search"
                id="search"
                type="text"
              />
            </div>
            <div className="input">
              {/* eslint-disable-next-line  jsx-a11y/label-has-associated-control */}
              <label htmlFor="search">First name:</label>
              <Input
                onKeyDown={(e) => {
                  if (e.code === 'Enter') searchUsers()
                }}
                value={givenNameSearch}
                onChange={(s) => setGivenNameSearch(s)}
                name="search"
                id="search"
                type="text"
              />
            </div>
            <div className="button">
              <Button onClick={searchUsers}>Search</Button>
            </div>
          </div>
        </div>
        <Table
          autoHeight
          wordWrap="break-word"
          data={users}
          loading={usersQuery.loading}
        >
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <TableData dataKey="name" content={renderName} />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <TableData dataKey="name" content={renderEmail} />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <TableData dataKey="name" content={renderRoles} />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Flags</Table.HeaderCell>
            <TableData dataKey="name" content={renderFlags} />
          </Table.Column>
          <Table.Column flexGrow={0.5}>
            <Table.HeaderCell>Actions</Table.HeaderCell>
            <TableData dataKey="action" content={renderActions} />
          </Table.Column>
        </Table>
        <div className="pagination">
          <PaginationControls
            nextPage={usersQuery.next || undefined}
            prevPage={usersQuery.prev || undefined}
          />
        </div>
      </Panel>
      <Modal hook={modal}>
        <OrganisationAssignModal
          user={modal.data}
          onConfirm={searchUsers}
          onClose={modal.hide}
        />
      </Modal>
      <Modal hook={attendantModal}>
        <AttendantAssignModal
          user={attendantModal.data}
          onConfirm={searchUsers}
          onClose={attendantModal.hide}
        />
      </Modal>
      <Modal hook={deleteUserModal}>
        <DeleteUserModal
          user={deleteUserModal.data}
          onConfirm={searchUsers}
          onClose={deleteUserModal.hide}
        />
      </Modal>

    </>
  )
}
