import React, { ReactElement } from 'react'
import { Navigate, Link } from 'react-router-dom'
import { Panel, Button } from 'rsuite'

import {
  useSelector, useFormState, useDispatch, usePageTitle
} from '../../app/hooks'
import { userSelectors, userActions } from '../../store/user/slice'
import { LoginForm } from '../../forms/LoginForm'
import { NooverLogo } from '../../components'

import './Login.css'
import { normaliseEmailAddress } from './helpers'

/**
 * Login panel component
 * @return {ReactElement}
 */
function Login (): ReactElement {
  usePageTitle('Sign in')
  const dispatch = useDispatch()
  const [formData, setField] = useFormState({
    email: '',
    password: '',
    givenName: '',
    familyName: '',
    phone: '',
  })
  const status = useSelector(userSelectors.getStatus)
  const user = useSelector(userSelectors.getUser)

  if (user && user.unconfirmed) return <Navigate to="/login/confirm" />

  /**
   * Submit the login form after validation is successful
   * @param {boolean} valid
   */
  const login = () => {
    dispatch(userActions.login({
      username: normaliseEmailAddress(formData.email),
      password: formData.password,
    }))
  }

  return (
    <div className="login-page">
      <div className="form -login">
        <NooverLogo aria-label="Noover" />
        <Panel>
          <LoginForm
            formValue={formData}
            onChange={setField}
            onSubmit={login}
            loading={status.loading}
            error={status.error}
          />
          <p>
            Don&apos;t have an account?
            <Button appearance="link" as={Link} to="signup">Sign up</Button>
          </p>
        </Panel>
      </div>
    </div>
  )
}

export {
  Login
}
